<template>
    <v-container class="pt-0" id="payments">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dates" label="Selecciona un rango de fechas" hide-details="" dense filled
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker range v-model="dates" scrollable :min="dates.length > 0 ? dates[0] : null" width="100%">
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" @click="dates = []">Cancelar</v-btn>
                <v-btn text color="primary" @click="menu = false; getPayments()">Confirmar</v-btn>
            </v-date-picker>
        </v-menu>
        <v-progress-linear v-if="loading" absolute top color="primary" indeterminate></v-progress-linear>

        <v-tabs grow>
            <v-tab>
                <v-icon left>
                    mdi-chart-bar
                </v-icon>
                Resumen
            </v-tab>
            <v-tab>
                <v-icon left>
                    mdi-cash
                </v-icon>
                Pagos
            </v-tab>
            <v-tab-item>
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="elevation-0">
                                    <v-card-text>
                                        <div class="d-flex justify-space-between align-center">
                                            <div>
                                                <span class="text-h5">
                                                    <v-icon left>mdi-cash</v-icon>
                                                    Importes Totales</span>
                                                <span class="text-h6"> ${{ totalAmout }}</span>
                                            </div>


                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" md="6">
                                <VueApexCharts type="bar" v-if="!loading" :options="{
                                    plotOptions: {
                                        bar: {
                                            distributed: true
                                        }

                                    },
                                    legend: {
                                        show: false
                                    },
                                    colors: statusData.fill,
                                    labels: statusData.labels, theme: {
                                        mode: $store.state.isDark ? 'dark' : 'light',
                                        palette: 'palette1',
                                    },
                                    title: {
                                        text: statusData.title,
                                        align: 'center',
                                        style: {
                                            fontSize: '16px',
                                        }
                                    },
                                }" :series="statusData.series"></VueApexCharts>
                                <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                            </v-col>

                            <v-col cols="12" md="6">
                                <VueApexCharts type="bar" v-if="!loading" :options="stackedBarOptions"
                                    :series="stackedBarSeries">
                                </VueApexCharts>
                                <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <VueApexCharts type="bar" v-if="!loading" :options="{

                                    labels: payMethodData.labels, theme: {
                                        mode: $store.state.isDark ? 'dark' : 'light',
                                        palette: 'palette1',
                                    },
                                    title: {
                                        text: payMethodData.title,
                                        align: 'center',
                                        style: {
                                            fontSize: '16px',
                                        }
                                    },
                                }" :series="payMethodData.series"></VueApexCharts>
                                <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                            </v-col>


                            <v-col cols="12" md="6">
                                <VueApexCharts type="bar" v-if="!loading" :options="{
                                    labels: monthsData.labels, theme: {
                                        mode: $store.state.isDark ? 'dark' : 'light',
                                        palette: 'palette1',
                                    },
                                    title: {
                                        text: monthsData.title,
                                        align: 'center',
                                        style: {
                                            fontSize: '16px',
                                        }
                                    },
                                }" :series="monthsData.series"></VueApexCharts>
                                <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <VueApexCharts type="bar" v-if="!loading" :options="{
                                    labels: amountData.labels, theme: {
                                        mode: $store.state.isDark ? 'dark' : 'light', palette: 'palette1',
                                    },
                                    title: {
                                        text: amountData.title,
                                        align: 'center',
                                        style: {
                                            fontSize: '16px',
                                        }
                                    },
                                }" :series="amountData.series"></VueApexCharts>
                                <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :headers="headers" :items="payments" :loading="loading" class="elevation-0"
                    @click:row="openPaymentDetails" mobile-breakpoint="0" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title class="pl-4">Lista de pagos</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" filled rounded dense append-icon="mdi-magnify" label="Buscar"
                                single-line hide-details></v-text-field>

                        </v-toolbar>
                    </template>




                    <!-- you can customize this as you need -->
                    <template v-slot:item.status="{ item }">
                        <v-chip v-if="item.status == 'Pendiente'" color="warning darken-2" dark>{{ item.status
                            }}</v-chip>
                        <v-chip v-else-if="item.status == 'Cancelado'" color="red" dark>{{ item.status }}</v-chip>
                        <v-chip v-else color="success" dark>{{ item.status }}</v-chip>
                    </template>

                    <template v-slot:item.amount="{ item }">

                        <v-chip v-if="item.amount == 0" dark>Libre</v-chip>
                        <v-chip v-else dark>{{ item.amount }} {{ item.amount == 1 ? 'Día' : 'Dias' }}</v-chip>
                    </template>


                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt }}
                    </template>
                </v-data-table>
            </v-tab-item>


        </v-tabs>



        <v-dialog v-model="paymentDetails" max-width="350">
            <v-card v-if="paymentObj">
                <v-card-title>
                    Detalles del pago
                    <v-spacer></v-spacer>
                    <v-btn icon @click="paymentDetails = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>


                    <p>
                        <v-chip v-if="paymentObj.status == 'Pendiente'" color="warning darken-2" dark>{{
                            paymentObj.status
                            }}</v-chip>
                        <v-chip v-else-if="paymentObj.status == 'Cancelado'" color="error" dark>{{ paymentObj.status
                            }}</v-chip>
                        <v-chip v-else color="success" dark>{{ paymentObj.status }}</v-chip>

                        <v-btn style="display:inline-block" class="ml-4"
                            v-if="paymentObj.status != 'Confirmado' && paymentObj.status != 'Cancelado'" color="success"
                            @click="updatePaymentStatus(paymentObj.id, 'Confirmado')" :loading="loadingStatus">
                            <v-icon left>mdi-check</v-icon>
                            Confirmar
                        </v-btn>
                    </p>
                    <p>Id Usuario: {{ paymentObj.civilId }}</p>
                    <p>Plan: {{ paymentObj.amount == 0 ? 'Libre' : paymentObj.amount }}</p>
                    <p>Fecha de cobro: {{ paymentObj.createdAt }}</p>
                    <p>Meses: {{ paymentObj.months }}</p>
                    <p>Metodo de pago: {{ paymentObj.payMethod }}</p>
                    <p>Monto:
                        <v-chip class="ml-2" dark>${{ paymentObj.total }}</v-chip>
                    </p>

                    <template>
                        <v-btn v-if="paymentObj.status != 'Cancelado'" block color="error" class="mt-6"
                            @click="updatePaymentStatus(paymentObj.id, 'Cancelado')" :loading="loadingStatus">
                            <v-icon left>mdi-close</v-icon>
                            Rechazar pago
                        </v-btn>

                        <v-btn block color="error darken-3" class="mt-6"
                            @click="updatePaymentStatus(paymentObj.id, 'Eliminado')" :loading="loadingStatus">
                            <v-icon left>
                                mdi-delete
                            </v-icon>
                            Rechazar & Eliminar
                        </v-btn>


                        <v-spacer></v-spacer>

                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { ref } from 'vue'
import { collection, getDocs, query, where, orderBy, startAt, endAt, getFirestore, setDoc, doc, addDoc, updateDoc, Timestamp, deleteDoc } from "firebase/firestore";
import moment from 'moment'
import { countBy, keys, values, groupBy, mapValues, uniq } from 'lodash'
import VueApexCharts from "vue-apexcharts";
import { logAuditEvent } from '@/error/audit.js';

export default {
    components: {
        "VueApexCharts": VueApexCharts,
    },
    name: 'PaymentRange',
    data() {

        return {
            payMethodData: {},
            monthsData: {},
            amountData: {},
            statusData: {},
            menu: false,
            dates: [],
            headers: [
                { text: 'Estado', value: 'status' },
                { text: 'ID Usuario', value: 'civilId' },
                { text: 'Fecha de cobro', value: 'createdAt' },
                { text: 'Tipo', value: 'amount' },
                { text: 'Meses', value: 'months' },
                { text: 'Metodo de pago', value: 'payMethod' },
                { text: 'Importe', value: 'total' },
                // Add more headers as you need
            ],
            payments: [],
            loading: false,
            paymentDetails: false,
            paymentObj: null,
            totalAmout: 0,
            loadingStatus: false,
            search: null,
            stackedBarOptions: {
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1',
                },
                chart: {
                    type: 'bar',
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    categories: []
                },
                title: {
                    text: 'Pagos Agrupados por Frecuencia Semanal y Meses',
                    align: 'center',
                    style: {
                        fontSize: '16px',
                    }
                },
                yaxis: {
                    title: {
                        text: 'Cantidad'
                    }
                },
                dataLabels: {
                    enabled: false
                },



            },
            stackedBarSeries: [],
        }
    },
    mounted() {

        if (this.$store.state.Auth.token.claims.type == 'superuser') {
            this.headers.push({ text: 'ID', value: 'id' })
        }

        this.getPayments()
    },
    methods: {
        aggregateData() {
            // Aggregate data by payment method
            const payMethodCount = countBy(this.payments, 'payMethod');

            //only keep the first 4 chars of every key
            this.payMethodData = {
                title: 'Metodos de pago',
                labels: this.$vuetify.breakpoint.smAndDown ? keys(payMethodCount).map(key => {

                    // check if key has spaces and split it by space then get the first letter of the first capitalize it and join with the 4 first chars of the second
                    // if the split is empty then return the first 4 chars of the key
                    return key.includes(' ') ? key.split(' ').map((word, index) => index == 0 ? word.charAt(0).toUpperCase() : word).join('.') : key.substring(0, 4)

                }) : keys(payMethodCount),
                series: values(payMethodCount),
            };

            // Aggregate data by months
            const monthsCount = countBy(this.payments, 'months');
            this.monthsData = {
                title: 'Meses de suscripcion',
                labels: keys(monthsCount),
                series: values(monthsCount),
            };

            // Aggregate data by amount
            const amountCount = countBy(this.payments, 'amount');
            this.amountData = {
                title: 'Planes',
                labels: keys(amountCount),
                series: values(amountCount),
            };

            // Aggregate data by amount
            const statusCount = countBy(this.payments, 'status');

            this.statusData = {
                title: 'Estado',
                labels: keys(statusCount),
                series: values(statusCount),
                fill: keys(statusCount).map((key) => {
                    if (key == "Pendiente") {
                        return '#ffc107'
                    }
                    if (key == "Confirmado") {
                        return '#4CAF50'
                    }
                    if (key == "Cancelado") {
                        return "#F44336"
                    }
                })
            };
            this.payMethodData.series = [{ data: this.payMethodData.series }];
            this.monthsData.series = [{ data: this.monthsData.series }];
            this.amountData.series = [{ data: this.amountData.series }]
            this.statusData.series = [{ data: this.statusData.series }]

            const groupedPayments = groupBy(this.payments, 'amount');
            const uniqueMonths = uniq(this.payments.map(p => p.months + '')).filter(month => month !== undefined);
            let stackedBarSeries = [];

            for (const month of uniqueMonths) {
                const seriesData = keys(groupedPayments).map(amount => {
                    const paymentsForAmount = groupedPayments[amount] || [];
                    return paymentsForAmount.filter(payment => payment.months === month).length;
                });

                stackedBarSeries.push({
                    name: `${month} Meses`,
                    data: seriesData
                });
            }

            stackedBarSeries = stackedBarSeries.sort((a, b) => parseInt(a.name) - parseInt(b.name));



            this.stackedBarOptions.xaxis.categories = keys(groupedPayments).map(amount => amount == 0 ? 'Libre' : `${amount} Veces/Semana`);

            this.stackedBarSeries = stackedBarSeries;
        },
        async getPayments() {
            this.totalAmout = 0
            const db = getFirestore()
            this.loading = true
            let start = null;
            let end = null;

            if (this.dates.length == 2) {
                start = new Date(this.dates[0] + " 00:00:00")
                end = new Date(this.dates[1] + " 23:59:59")
            } else {
                // here start is the start of the month and end is today at 23:59:59
                let date = moment()
                let startOfMonth = moment().startOf('month')

                start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
            }
            const q = query(
                collection(db, "payments"),
                where("createdAt", ">=", start),
                where("createdAt", "<=", end)
                /* ,
                where("type", "==", "usuario") */
            );
            const querySnapshot = await getDocs(q);

            this.payments = querySnapshot.docs.map(doc => {
                let docData = doc.data()

                let payMethod = doc.data().payMethod
                let status = 'Confirmado'
                if (payMethod != 'EFECTIVO' && payMethod != 'TARJETA') {
                    if (!doc.data().status) {
                        status = 'Pendiente'
                    } else {
                        status = doc.data().status
                    }
                } else {
                    status = 'Confirmado'
                }

                if (docData.payMethod == 'TARJETA') {
                    docData.payMethod = docData.cardType
                }




                this.totalAmout += parseInt(doc.data().total)
                return {
                    ...docData,
                    id: doc.id,
                    civilId: doc.data().id,
                    createdAt: moment(doc.data().createdAt.toDate()).format('DD/MM/YYYY'),
                    status: status,
                }


            })

            if (this.payments.length > 0) {
                this.aggregateData();
            }


            this.loading = false

        },
        openPaymentDetails(paymentObj) {
            this.paymentDetails = true
            this.paymentObj = paymentObj
        },
        async updatePaymentStatus(id, status) {

            try {

                if (status == this.paymentObj.status) {
                    return
                }


                if (status == 'Cancelado' || status == 'Eliminado') {

                    let confirm = await this.$confirm("¿Estas seguro de que deseas cancelar este pago? Esta accion no se puede deshacer.",
                        {
                            color: "error",
                            title: status == "Cancelado" ? "Cancelar pago" : "Eliminar pago",
                            buttonTrueText: "Confirmar",
                        }
                    )

                    if (!confirm) {
                        return
                    }
                }

                this.loadingStatus = true

                const db = getFirestore()
                const paymentRef = doc(db, "payments", id);
                await updateDoc(paymentRef, {
                    status: status
                });

                await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Updated payment status to ${status} for payment ${JSON.stringify(this.paymentObj)}`)


                if (status == 'Cancelado' || status == 'Eliminado') {

                    if (this.paymentObj.status != "Cancelado") {
                        const userRef = doc(db, "users", this.paymentObj.civilId);
                        const months = parseInt(this.paymentObj.months)
                        const date = new Date(this.paymentObj.endOfSubscription.seconds * 1000)
                        const endOfSubscription = moment(date).subtract(months, 'months').toDate()
                        await updateDoc(userRef, {
                            endOfSubscription: Timestamp.fromDate(endOfSubscription)
                        });

                        await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Cancelled subscription for user ${this.paymentObj.civilId}, actual end of subscription ${endOfSubscription}, previous end of subscription ${date} for payment ${JSON.stringify(this.paymentObj)}`)
                    }


                    if (status == "Eliminado") {
                        await deleteDoc(paymentRef)
                        await logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `Deleted payment ${JSON.stringify(this.paymentObj)}`)
                    }
                }

                this.paymentObj = null

                if (status == 'Cancelado' || status == "Confirmado") {
                    //update the payment status in the table
                    this.payments = this.payments.map(payment => {
                        if (payment.id == id) {
                            payment.status = status
                        }
                        return payment
                    })
                } else {
                    //remove the payment from the table
                    this.payments = this.payments.filter(payment => payment.id != id)
                }


                this.aggregateData();
                this.loadingStatus = false
                this.paymentDetails = false
                this.$notify({
                    group: 'feedback',
                    title: 'Pago actualizado',
                    text: 'El pago ha sido actualizado correctamente',
                    type: 'success'
                })
            } catch (error) {
                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error updating payment ${JSON.stringify(this.paymentObj)} error: ${error}`)
                this.loadingStatus = false
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Ha ocurrido un error al actualizar el pago',
                    type: 'error'
                })
            }
        }
    }
}
</script>

<style>
#payments .v-skeleton-loader__image {
    height: 100% !important;
}
</style>
