<template>
    <v-container>
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field filled hide-details="" v-model="dates" label="Seleccionar un rango de fechas" readonly
                    v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
            </template>
            <v-date-picker v-model="dates" range width="100%" scrollable :min="dates.length > 0 ? dates[0] : null">
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" @click=" dates = []">Cancelar</v-btn>
                <v-btn text color="primary darken-1" @click="menu = false; fetchSurveyReport()">Confirmar</v-btn>

            </v-date-picker>
        </v-menu>



        <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab>
                <v-icon left>
                    mdi-chart-bar
                </v-icon>
                Resumen
            </v-tab>
            <v-tab>
                <v-icon left>
                    mdi-format-list-bulleted
                </v-icon>
                Detalle
            </v-tab>

            <v-tab-item>
                <v-data-table :headers="headers" :items="reportData" class="elevation-1 mt-4" hide-default-footer
                    :loading="loading" mobile-breakpoint="0">

                    <template v-slot:item.averageRating="{ item }">
                        <div v-if="item.question.indexOf(')') == -1">


                            <v-progress-circular :color="returnColor(item.averageRating)" :size="60" :width="6"
                                class="ma-2" :value="item.averageRating * 20">

                                <!-- show percent -->
                                <template>
                                    <span>{{ (item.averageRating * 20).toFixed(1) }} %</span>
                                </template>



                            </v-progress-circular>


                        </div>
                    </template>
                    <template v-slot:item.commentCount="{ item }">




                        <p color="warning" class="text-h6 rounde-lg mt-2" large v-if="item.question.indexOf(')') != -1">
                            <v-icon left>
                                mdi-comment
                            </v-icon>
                            {{ item.commentCount
                            }} Comentarios

                            <!--show how many per sentiment string type-->
                            <br v-if="item['Muy positivo']" />
                            <v-chip small color="green darken-2" v-if="item['Muy positivo']">
                                <v-icon left>
                                    mdi-emoticon-excited-outline
                                </v-icon>
                                {{ item['Muy positivo'] }} Muy positivos
                            </v-chip>
                            <br v-if="item['Positivo']" />
                            <v-chip small color="green darken-1" v-if="item['Positivo']">
                                <v-icon left>
                                    mdi-emoticon-happy-outline
                                </v-icon>
                                {{ item['Positivo'] }} Positivos
                            </v-chip>
                            <br v-if="item['Neutral']" />
                            <v-chip small color="grey" v-if="item['Neutral']">
                                <v-icon left>
                                    mdi-emoticon-neutral-outline
                                </v-icon>
                                {{ item['Neutral'] }} Neutrales
                            </v-chip>

                            <br v-if="item['Negativo']" />
                            <v-chip small color="red darken-1" v-if="item['Negativo']">
                                <v-icon left>
                                    mdi-emoticon-sad-outline
                                </v-icon>
                                {{ item['Negativo'] }} Negativos
                            </v-chip>
                            <br v-if="item['Muy negativo']" />
                            <v-chip small color="red darken-2" v-if="item['Muy negativo']">
                                <v-icon left>
                                    mdi-emoticon-sad-outline
                                </v-icon>
                                {{ item['Muy negativo'] }} Muy negativos
                            </v-chip>
                        </p>
                    </template>


                </v-data-table>
            </v-tab-item>

            <v-tab-item>

                <v-data-table :headers="surveyListHeaders" :items="surveyList" class="elevation-1 mt-4" sort-by="date"
                    sort-desc="true" :loading="loading" mobile-breakpoint="0">



                    <template v-slot:item.sentiment="{ item }">
                        <v-chip small v-if="item.sentiment == 0">
                            Neutral
                        </v-chip>
                        <v-chip small color="success" v-else-if="item.sentiment > 0 && item.sentiment <= 0.5">
                            Positivo
                        </v-chip>
                        <v-chip small color="success" v-else-if="item.sentiment > 0.5">
                            Muy positivo
                        </v-chip>
                        <v-chip small color="error" v-else-if="item.sentiment < 0 && item.sentiment >= -0.5">
                            Negativo
                        </v-chip>
                        <v-chip small color="error" v-else-if="item.sentiment < -0.5">
                            Muy negativo
                        </v-chip>

                        <template v-else>
                            -
                        </template>


                    </template>



                </v-data-table>

            </v-tab-item>



        </v-tabs>
    </v-container>
</template>

<script>
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import moment from "moment";
const db = getFirestore();

export default {
    data() {
        return {
            menu: false,
            dates: [],
            reportData: [],
            headers: [
                { text: 'Pregunta', value: 'question' },
                {
                    text: 'Promedio de satisfacción', value: 'averageRating',
                    sortable: false,
                },
                {
                    text: 'Comentarios', value: 'commentCount',
                    sortable: false,
                },
            ],
            surveyList: [],
            surveyListHeaders: [],
            tab: 0,
            loading: false,


        };
    },
    methods: {
        returnColor(rating) {
            let value = parseFloat(rating);
            if (value <= 1) {
                return "red darken-2";
            } else if (value <= 2) {
                return "orange darken-2";
            } else if (value <= 3) {
                return "grey";
            } else if (value <= 4) {
                return "warning darken-2";
            }
            else if (value <= 4.5) {
                return "warning darken-1";
            }

            else if (value <= 5) {
                return "primary";
            }
        },
        async fetchSurveyReport() {
            this.loading = true;
            let start, end;

            if (this.dates.length == 2) {
                start = new Date(this.dates[0] + " 00:00:00")
                end = new Date(this.dates[1] + " 23:59:59")
            } else {
                // here start is the start of the month and end is today at 23:59:59
                let date = moment()
                let startOfMonth = moment().startOf('month')

                start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
            }

            const surveysRef = collection(db, "surveys");
            const q = query(surveysRef, where("date", ">=", start), where("date", "<=", end));
            const querySnapshot = await getDocs(q);

            let surveyList = [];
            let surveyData = [];
            querySnapshot.forEach((doc) => {
                surveyList.push(doc.data());
                if (doc.data().data) {
                    surveyData.push(doc.data().data);
                }
            });

            //make surveylistHeaders
            if (surveyList.length > 0) {
                let dataArray = surveyList[0].data

                this.surveyListHeaders = dataArray.map((q) => {
                    return { text: q.question, value: q.question };

                });

                this.surveyListHeaders.unshift({ text: 'Usuario', value: 'user' }, { text: 'Fecha', value: 'date' });

                this.surveyListHeaders.push({ text: '', value: 'sentiment', sortable: false });

                this.surveyList = surveyList.map((survey) => {
                    let data = survey.data;
                    let user = survey.user;
                    let date = moment(survey.date.toDate()).format('DD/MM/YYYY HH:mm');
                    let surveyData = {};
                    data.forEach((q) => {
                        if (q.comment) {
                            surveyData[q.question] = q.comment;

                            if (survey.seen) {
                                surveyData['sentiment'] = q.sentiment;
                            }

                        } else {
                            surveyData[q.question] = q.rating ? parseInt(q.rating) : '-';
                        }
                    });

                    console.log(survey.seen)
                    return { user, date, ...surveyData };
                });
            }

            let summary = surveyData.reduce((acc, survey) => {
                survey.forEach((q, index) => {
                    if (!acc[index]) {
                        acc[index] = { question: q.question, totalRating: 0, count: 0, commentCount: 0 };
                    }
                    if (q.rating) {
                        acc[index].totalRating += q.rating;
                        acc[index].count += 1;
                    }
                    if (q.comment) {
                        acc[index].commentCount += 1;

                        if (typeof q.sentiment != 'undefined') {
                            //get sentiment value between -1 and 1 from sentiment attribute
                            let sentiment = q.sentiment;

                            let sentimentString = "";
                            if (sentiment > 0.5) {
                                sentimentString = "Muy positivo";
                            } else if (sentiment > 0) {
                                sentimentString = "Positivo";
                            } else if (sentiment == 0) {
                                sentimentString = "Neutral";
                            } else if (sentiment < -0.5) {
                                sentimentString = "Muy negativo";
                            } else if (sentiment < 0) {
                                sentimentString = "Negativo";
                            }
                            acc[index][sentimentString] = acc[index][sentimentString] ? acc[index][sentimentString] + 1 : 1;

                        }
                    }
                });
                return acc;
            }, []);

            this.reportData = summary.map((q) => ({
                question: q.question,
                averageRating: (q.totalRating / q.count).toFixed(2),
                commentCount: q.commentCount,
                ...q,
            }));

            this.loading = false;
        },
    },
    mounted() {
        this.fetchSurveyReport();
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
